import React from "react"
import styles from "./footer.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Instagram from "../../static/instagram-round-line.svg"
import Youtube from "../../static/youtube-round-line.svg"
import Facebook from "../../static/facebook-round-line.svg"
import Twitter from "../../static/twitter-round-line.svg"

export default () => (
  <div className={`${styles.footer} bg-light-grey border`}>
    <div className="container">
      <div className="row py-2">
        <div className="six columns text-left">
          <h4>Follow us.</h4>
          
          <div className="py-1">
            <a
              href="https://www.instagram.com/panls_surfaces/"
              className={`${styles.footerSocialIcon} d-inline`}
              target="_blank"
            >
              <img src={Instagram} alt="Instagram" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCndVsJZhYNGrnnXH9tWpiLQ/"
              className={`${styles.footerSocialIcon} d-inline`}
              target="_blank"
            >
              <img src={Youtube} alt="Youtube" />
            </a>
            <a
              href="https://www.facebook.com/panls.surfaces"
              className={`${styles.footerSocialIcon} d-inline`}
              target="_blank"
            >
              <img src={Facebook} alt="Facebook" />
            </a>
            <a
              href="https://twitter.com/panls_surfaces"
              className={`${styles.footerSocialIcon} d-inline`}
              target="_blank"
            >
              <img src={Twitter} alt="Twitter" />
            </a>
          </div>
        </div>
        <div className="six columns">
          <div className="row">
            <div className="four columns">
              <h4 className={styles.footerSectionHeading}>Products</h4>
              <p>
                <AniLink
                  paintDrip
                  hex="#ea4c12"
                  duration={0.75}
                  to="/limitless/"
                >
                  Limitless
                </AniLink>
              </p>
              <p>
                <AniLink paintDrip hex="#ea4c12" duration={0.75} to="/rapid/">
                  Rapid
                </AniLink>
              </p>
              <p>
                <AniLink paintDrip hex="#ea4c12" duration={0.75} to="/dash/">
                  Dash
                </AniLink>
              </p>
              <p>
                <AniLink paintDrip hex="#ea4c12" duration={0.75} to="/sports/">
                  Sports
                </AniLink>
              </p>
            </div>
            <div className="four columns">
              <h4 className={styles.footerSectionHeading}>Company</h4>
              <p>
                <AniLink paintDrip hex="#ea4c12" duration={0.75} to="/buy/">
                  Where to buy
                </AniLink>
              </p>
              <p>
                <AniLink paintDrip hex="#ea4c12" duration={0.75} to="/terms-privacy/">
                  Terms & Privacy
                </AniLink>
              </p>
       
            </div>
            <div className="four columns">
              <h4 className={styles.footerSectionHeading}>Panls®</h4>
              <p><a href="mailto:sales@panls.com">sales@panls.com</a></p>
              <p><a href="tel:8775717904">(877) 571-7904</a></p>
              <p>205 Westwood Ave.<br /> Long Branch, NJ 07740</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <p className="">© 2020 Panls® All Rights Reserved.</p>
      </div>
    </div>
  </div>
)
