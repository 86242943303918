import React from "react"
import styles from "./menu.module.css"
import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.state = {
      collapsed: true,
    }
  }
  toggleMenu() {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }
  render() {
    const showMenu = this.state.collapsed ? "" : `${styles.navListShow}`
    return (
      <div className={styles.navBar}>
        <div className={styles.navBarContent}>
          <div className="logo">
            <AniLink paintDrip hex="#ea4c12" duration={0.75} to="/">
              <img
                alt="Panls"
                src="https://res.cloudinary.com/iwh/image/upload/w_150,c_scale/q_auto,g_center/assets/1/7/panls_brandlogoconcept_recolor_v2w-reg.png"
                height="50"
                className={styles.navLogo}
              />
            </AniLink>
          </div>
          <div className={`${styles.navToggle}`} onClick={this.toggleMenu}>
            <p>&#9776;</p>
          </div>
          <nav className={`${styles.navListWrapper} ${showMenu}`}>
            <div className={styles.navLink}>
              <AniLink paintDrip hex="#ea4c12" duration={0.75} to="/limitless/">
                Limitless
              </AniLink>
            </div>
            <div className={styles.navLink}>
              <AniLink paintDrip hex="#ea4c12" duration={0.75} to="/rapid/">
                Rapid
              </AniLink>
            </div>
            <div className={styles.navLink}>
              <AniLink paintDrip hex="#ea4c12" duration={0.75} to="/dash/">
                Dash
              </AniLink>
            </div>
            <div className={styles.navLink}>
              <AniLink paintDrip hex="#ea4c12" duration={0.75} to="/sports/">
                Sports
              </AniLink>
            </div>
            <div className={styles.navLinkBtn}>
              <AniLink
                paintDrip
                hex="#ea4c12"
                duration={0.75}
                to="/buy/"
                className={`${styles.buyBtn} btn`}
              >
                How to Buy
              </AniLink>
            </div>
          </nav>
        </div>
      </div>
    )
  }
}
export default Nav
